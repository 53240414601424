<div class="container" [class]="body?.primary.arrow ? 'mb-5 mb-lg-6' : 'my-5 my-lg-6'">
    @if (body?.primary.arrow) {
    <div class="row d-flex justify-content-center">
        <div class="col-lg-8" appAos>
            <img src="./assets/images/arrow-curly-down.svg" alt="Arrow" class="w-100 arrow" />
        </div>
    </div>
    }
    <div class="background-pink px-4 py-5 p-lg-6" [class.margin-to-arrow]="body?.primary.arrow">
        <div class="row" appAos>
            @if (body.primary.image?.url) {
            <div class="col-lg-6 mb-5 mb-lg-0">
                <img [src]="body.primary.image.url" [alt]="body.primary.image.alt" class="w-100" />
            </div>
            }
            <div class="col-lg" [class.ms-lg-5]="body.primary.image.url">
                <div [innerHTML]="body?.primary.title | renderHtml : htmlSerializer"></div>
                <div [innerHTML]="body?.primary.description | renderHtml"></div>
                <app-link [link]="body.primary.button_link" [action]="body.primary.button_action" [cssClass]="'btn btn-black mt-4'">
                    {{ body?.primary.button_title }}
                </app-link>
            </div>
        </div>
    </div>
</div>
