<div class="container py-5 py-xl-6">
    <div [class]="body.primary.pink_background ? 'background-pink px-3 py-5 p-xl-5 py-xl-6' : ''" appAos>
        <div class="row d-flex align-items-center">
            <div class="col-xl-7" [class]="body.primary.media_right ? 'order-xl-1 ps-xl-5' : 'pe-xl-5'">
                @if (body.primary.video) {
                <video class="w-100" controls [poster]="body.primary.thumbnail?.url">
                    <source [src]="'/assets/videos/' + body.primary.video" type="video/mp4" />
                </video>
                } @else {
                <img [src]="body.primary.thumbnail?.url" [alt]="body.primary.thumbnail?.alt" class="w-100" />
                }
            </div>
            <div class="col-xl mt-5 mt-xl-0" [class.order-xl-0]="body?.primary?.media_right">
                <p class="heading" [class.text-white]="body?.primary.pink_background">
                    {{ body.primary.heading }}
                </p>
                <div [innerHTML]="body?.primary.title | renderHtml : htmlSerializer"></div>
                <div [innerHTML]="body?.primary.description | renderHtml"></div>
                <app-link [link]="body?.primary.button_link" [cssClass]="'btn btn-black mt-4'" [action]="body?.primary.button_action">
                    {{ body?.primary.button_title }}
                </app-link>
            </div>
        </div>
    </div>
</div>
