<!--TODO REPLACE IF SCULLY RUNNING-->
<div class="container py-5">
    <div class="row">
        @if (body.primary.carousel_mode) {
        <div class="col-12">
            <carousel [interval]="15000" [pauseOnFocus]="true">
                @for (rating of body.items | order : 'date' : true; track rating) {
                <slide>
                    <ng-container [ngTemplateOutlet]="ratingTemplate" [ngTemplateOutletContext]="{ rating: rating }"> </ng-container>
                </slide>
                }
            </carousel>
        </div>
        } @else { @for (rating of body.items | order : 'date' : true; track rating) {
        <div class="col-12">
            <ng-container [ngTemplateOutlet]="ratingTemplate" [ngTemplateOutletContext]="{ rating: rating }"> </ng-container>
        </div>
        } } @if (body.primary.carousel_mode) {
        <div class="col-12 mt-5">
            <a routerLink="/bewertungen" class="btn btn-black">Alle Bewertungen</a>
        </div>
        }
    </div>
</div>

<ng-template let-rating="rating" #ratingTemplate>
    <div class="mt-5 px-4 px-lg-5 py-5" [class]="body.primary.carousel_mode ? 'background-pink' : 'shadow'">
        <p class="text-size-m white-on-pink">{{ rating.name }}</p>
        <div class="d-flex align-items-center">
            <rating [(ngModel)]="rating.stars" [max]="5" [readonly]="true" class="white-on-pink"></rating>
            @if (!body.primary.carousel_mode) {
            <span class="ms-4 color-grey">{{ rating.dateDescription }}</span>
            }
        </div>
        <div [innerHTML]="rating.description | renderHtml" class="mt-4"></div>
    </div>
</ng-template>
