<div class="background-pink py-5 py-lg-6 my-5 my-lg-6">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 text-center text-lg-start" appAos>
                <div [innerHTML]="body.primary.description_top | renderHtml"></div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12" appAos>
                <div [innerHTML]="body.primary.catch_line | renderHtml : htmlSerializer"></div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-8 pe-lg-6" appAos>
                <img src="../../../../../assets/images/arrow-curly-right.svg" alt="Arrow" class="w-100 d-none d-lg-block" />
                <img src="../../../../../assets/images/arrow-curly-down-white.svg" alt="Arrow down" class="w-100 d-lg-none" />
            </div>
            <div class="col-lg-4 pt-4 pt-lg-6 text-center text-lg-start" appAos>
                <div [innerHTML]="body.primary.description_bottom | renderHtml"></div>
                <app-link [link]="body.primary.button_link" [action]="body.primary.button_action" [cssClass]="'btn btn-black mt-4'">
                    {{ body?.primary.button_title }}
                </app-link>
            </div>
        </div>
    </div>
</div>
