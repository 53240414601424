import { NgTemplateOutlet } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { BooleanField, DateField, KeyTextField, RichTextField, SelectField, Slice } from '@prismicio/client';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { RatingModule } from 'ngx-bootstrap/rating';
import { OrderPipe } from '../../order.pipe';
import { RenderHtmlPipe } from '../render-html/render-html.pipe';

@Component({
    selector: 'app-ratings-slice',
    templateUrl: './ratings-slice.component.html',
    styleUrls: ['./ratings-slice.component.scss'],
    standalone: true,
    imports: [CarouselModule, NgTemplateOutlet, RouterLink, RatingModule, FormsModule, RenderHtmlPipe, OrderPipe]
})
export class RatingsSliceComponent implements OnInit {
    @Input() body!: RatingsSlice;

    constructor() {}

    ngOnInit(): void {
        this.body.items.map((rating) => {
            rating.dateDescription = this.getDateDescription(rating.date as string);
        });
    }

    private getDateDescription(date: string): string {
        const totalMonthAgo = Math.floor((Date.now() - Number(new Date(date))) / 30 / 24 / 3600 / 1000);
        switch (totalMonthAgo) {
            case 0:
                return 'diesen Monat';
            case 1:
                return 'letzten Monat';
            default:
                return `vor ${totalMonthAgo} Monaten`;
        }
    }
}

export type RatingsSlice = Slice<
    'ratings',
    {
        carousel_mode: BooleanField;
    },
    Rating
>;

export type Rating = {
    name: KeyTextField;
    description: RichTextField;
    stars: SelectField;
    date: DateField;
    dateDescription?: string;
};
