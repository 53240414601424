import { Component, Input, OnInit } from '@angular/core';
import { ImageField, KeyTextField, LinkField, RichTextField, SelectField, Slice, TitleField } from '@prismicio/client';
import { HTMLMapSerializer } from '@prismicio/helpers';
import { AosDirective } from '../../aos/aos.directive';
import { LinkComponent } from '../../link/link.component';
import { RenderHtmlPipe } from '../render-html/render-html.pipe';

@Component({
    selector: 'app-pink-block-slice',
    templateUrl: './pink-block-slice.component.html',
    styleUrls: ['./pink-block-slice.component.scss'],
    standalone: true,
    imports: [AosDirective, LinkComponent, RenderHtmlPipe]
})
export class PinkBlockSliceComponent implements OnInit {
    @Input() body!: PinkBlockSlice;

    constructor() {}

    ngOnInit(): void {}

    htmlSerializer: HTMLMapSerializer = {
        heading3: ({ children }) =>
            `<h3 class="${this.body.primary.image.url ? 'color-white' : 'text-size-xl color-white'}">${children}</h3>`
    };
}

export type PinkBlockSlice = Slice<
    'pink_block',
    {
        arrow: boolean;
        title: TitleField;
        description: RichTextField;
        button_title: KeyTextField;
        button_link: LinkField;
        button_action: SelectField;
        image: ImageField;
    },
    never
>;
