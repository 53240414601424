<div class="container py-5 py-lg-6">
    @for (talent of body.items; track talent) {
    <div class="row gx-lg-5 mt-6 mt-lg-5">
        <div class="col-lg-6 position-relative" [class.order-lg-1]="talent.is_image_right" appAos>
            <video class="w-100" controls [poster]="talent.image.url">
                <source [src]="'/assets/videos/' + talent.video" type="video/mp4" />
            </video>
        </div>
        <div class="col-lg-6 mt-4 mt-lg-0" [class.order-lg-0]="talent.is_image_right" appAos>
            <div class="background-pink d-flex flex-column justify-content-between h-100 p-4 p-lg-5">
                <p class="color-white text-size-l">"{{ talent.quote }}"</p>
                <div>
                    <p class="color-white text-size-m mb-0">
                        {{ talent.name }}
                    </p>
                    <p class="color-white mb-0">{{ talent.description }}</p>
                </div>
            </div>
        </div>
    </div>
    }
</div>
