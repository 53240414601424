import { Component, Input, OnInit } from '@angular/core';
import { BooleanField, ImageField, KeyTextField, Slice } from '@prismicio/client';
import { AosDirective } from '../../aos/aos.directive';

@Component({
    selector: 'app-talents-slice',
    templateUrl: './talents-slice.component.html',
    styleUrls: ['./talents-slice.component.scss'],
    standalone: true,
    imports: [AosDirective]
})
export class TalentsSliceComponent implements OnInit {
    @Input() body!: TalentsSlice;

    constructor() {}

    ngOnInit(): void {}
}

export type TalentsSlice = Slice<'talents', never, Talent>;

export type Talent = {
    quote: KeyTextField;
    name: KeyTextField;
    description: KeyTextField;
    image: ImageField;
    thumbnail: ImageField;
    video: KeyTextField;
    is_image_right: BooleanField;
};
