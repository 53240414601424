import { Component, Input, OnInit } from '@angular/core';
import { ImageField, KeyTextField, LinkField, RichTextField, SelectField, Slice, TitleField } from '@prismicio/client';
import { HTMLMapSerializer } from '@prismicio/helpers';
import { AosDirective } from '../../aos/aos.directive';
import { LinkComponent } from '../../link/link.component';
import { RenderHtmlPipe } from '../render-html/render-html.pipe';

@Component({
    selector: 'app-video-slice',
    templateUrl: './video-slice.component.html',
    styleUrls: ['./video-slice.component.scss'],
    standalone: true,
    imports: [AosDirective, LinkComponent, RenderHtmlPipe]
})
export class VideoSliceComponent implements OnInit {
    @Input() body!: VideoSlice;

    constructor() {}

    ngOnInit(): void {}

    htmlSerializer: HTMLMapSerializer = {
        heading3: ({ children }) =>
            `<h3 class="${this.body.primary.pink_background ? 'color-white' : ''} text-size-l">` + children + '</h3>'
    };
}

export type VideoSlice = Slice<
    'video',
    {
        pink_background: boolean;
        media_right: boolean;
        heading: string;
        title: TitleField;
        description: RichTextField;
        button_title: KeyTextField;
        button_action: SelectField;
        button_link: LinkField;
        image: ImageField;
        video: KeyTextField;
        thumbnail: ImageField;
    },
    never
>;
