import { Component, Input, OnInit } from '@angular/core';
import { KeyTextField, LinkField, RichTextField, SelectField, Slice } from '@prismicio/client';
import { HTMLMapSerializer } from '@prismicio/helpers';
import { AosDirective } from '../../aos/aos.directive';
import { LinkComponent } from '../../link/link.component';
import { RenderHtmlPipe } from '../render-html/render-html.pipe';

@Component({
    selector: 'app-catch-line-slice',
    templateUrl: './catch-line-slice.component.html',
    styleUrls: ['./catch-line-slice.component.scss'],
    standalone: true,
    imports: [AosDirective, LinkComponent, RenderHtmlPipe]
})
export class CatchLineSliceComponent implements OnInit {
    @Input() body!: ImageAndTextSlice;

    constructor() {}

    ngOnInit(): void {}

    htmlSerializer: HTMLMapSerializer = {
        paragraph: ({ children }) => `<p class="text-size-2xl text-center color-white">` + children + '</p>'
    };
}

export type ImageAndTextSlice = Slice<
    'schlagzeile',
    {
        description_top: RichTextField;
        catch_line: RichTextField;
        description_bottom: RichTextField;
        button_title: KeyTextField;
        button_link: LinkField;
        button_action: SelectField;
    },
    Buzzword
>;

export type Buzzword = {
    buzzword: string;
};
